import * as React from "react";
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import GoogleMapReact from "google-map-react";

const IndexPage = () => {
  const nameCard = (locale) => `BEGIN:VCARD
VERSION:3.0
FN;CHARSET=UTF-8:${locale==='en'?'Dr. Claudia Ng Kok Yu':'吳珏儒醫生'}
N;CHARSET=UTF-8:;${locale==='en'?'Dr. Claudia Ng Kok Yu':'吳珏儒醫生'};;;
EMAIL;CHARSET=UTF-8;type=HOME,INTERNET:cn@drclaudiang.com.hk
EMAIL;CHARSET=UTF-8;type=WORK,INTERNET:info@spinecentre.com.hk
TEL;TYPE=WORK,VOICE:+85221119911
LABEL;CHARSET=UTF-8;TYPE=WORK:${locale==='en'?'Hong Kong Spine Centre':'香港護脊中心'}
ADR;CHARSET=UTF-8;TYPE=WORK:;;${locale==='en'?`7/F Parker House, 72 Queen's Road, Central, Hong Kong`:'香港中環皇后大道中72號百佳大廈7樓全層'};;;;
TITLE;CHARSET=UTF-8:${locale==='en'?'Hong Kong Registered Chiropractor':'香港註冊脊醫'}
URL;type=WORK;CHARSET=UTF-8:https://www.spinecentre.com.hk
NOTE;CHARSET=UTF-8:${locale==='en'?'Clinic Hours:\\nMon - Fri: 10am - 1:30pm / 3pm - 6pm\\nSat: 10am - 1pm\\nSun & Public Holiday: Closed':'診所時間:\\n星期一至五: 上午10時至下午1:30時 / 下午3時至6時\\n星期六：上午10時至下午1時\\n星期日及公眾假期休息'}
REV:2021-07-10T03:03:29.733Z
END:VCARD
`
  const download = (locale) => {
    const link = document.createElement("a");
    link.setAttribute("href", encodeURI("data:text/vcard;charset=utf-8," + nameCard(locale)));
    link.setAttribute("download", `namecard.vcf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const renderMarkers = (map, maps) => {
    let marker = new maps.Marker({
    position: { lat: 22.282833, lng: 114.155489 },
    map,
    title: 'Hong Kong Spine Centre'
    });
    return marker;
   };

  return (
    <main>
      <GatsbySeo
        title="Dr. Claudia Ng Kok Yu"
        description="Hong Kong Registered Chiropractor"
        openGraph={{
          url: 'https://www.drclaudiang.com.hk',
          title: 'Dr. Claudia Ng Kok Yu',
          description: 'Hong Kong Registered Chiropractor',
          images: [
            {
              url: 'https://www.drclaudiang.com.hk/logo.svg'
            }
          ]
        }}
      />
      <header>
        <img className="w-64 h-64 mx-auto" src="/logo.svg" alt="Hong Kong Spine Centre"/>
      </header>
      <div className="flex flex-col justify-evenly space-y-6 mx-8 mb-24 md:flex-row md:space-y-0">
        <section className="rounded-lg shadow-lg px-10 py-6 border">
          <dl>
            <dt className="text-gray-800 text-lg font-medium">吳珏儒醫生 BSc (Hons), DC</dt>
            <dd className="text-gray-500">香港註冊脊醫</dd>
          </dl>
          <address className="not-italic">地址：香港中環皇后大道中 72 號 7 樓全層</address>
          <ul className="mt-6">
            <li>電話：852-2111-9911</li>
            <li>網址：<a href="https://www.drclaudiang.com.hk">www.drclaudiang.com.hk</a></li>
            <li>電郵: <a href="mailto:cn@drclaudiang.com.hk">cn@drclaudiang.com.hk</a> / <a href="mailto:info@spinecentre.com.hk">info@spinecentre.com.hk</a></li>
          </ul>
          <ul className="mt-6">
            <li>診所時間:</li>
            <li>星期一至五: 上午10時至下午1:30時 / 下午3時至6時</li>
            <li>星期六：上午10時至下午1時</li>
            <li>星期日及公眾假期休息</li>
          </ul>
          <button
            type="button"
            onClick={() => download('ch')}
            className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm mt-6 text-white bg-mango md:hidden hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
          >
            電子名片
          </button>
        </section>
        <section className="rounded-lg shadow-lg px-10 py-6 border">
          <dl>
            <dt className="text-gray-800 text-lg font-medium">Dr. Claudia Ng Kok Yu, BSc (Hons), DC</dt>
            <dd className="text-gray-500">Hong Kong Registered Chiropractor</dd>
          </dl>
          <address className="not-italic">Address: 7/F, Parker House, 72 Queen's Rd Central, Hong Kong</address>
          <ul className="mt-6">
            <li>Tel: 852-2111-9911</li>
            <li>Website: <a href="https://www.drclaudiang.com.hk">www.drclaudiang.com.hk</a></li>
            <li>Email: <a href="mailto:cn@drclaudiang.com.hk">cn@drclaudiang.com.hk</a> / <a href="mailto:info@spinecentre.com.hk">info@spinecentre.com.hk</a></li>
          </ul>
          <ul className="mt-6">
            <li>Clinic Hours:</li>
            <li>Mon - Fri: 10am - 1:30pm / 3pm - 6pm</li>
            <li>Sat: 10am - 1pm</li>
            <li>Sun &amp; Public Holiday: Closed</li>
          </ul>
          <button
            type="button"
            onClick={() => download('en')}
            className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white mt-6 bg-mango md:hidden hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
          >
            vCard
          </button>
        </section>
      </div>
      <div className="mb-8 w-full h-[420px] bg-red-50">
        <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyCnxkxuugWrWZBnWdgFQgnjS2ssNQMgzs8' }}
            defaultCenter={{
              lat: 22.282833,
              lng: 114.155489
            }}
            onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
            defaultZoom={19}
        >
          <div className="bg-white rounded-md shadow-md h-16 w-[240px] py-2 px-4 font-medium text-base text-gray-700">
            Hong Kong Spine Centre <br/>
            香港護脊中心
          </div>
        </GoogleMapReact>
      </div>
    </main>
  )
}

export default IndexPage
